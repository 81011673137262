import React, {Component} from 'react';
import PageTitle from "../Components/PageTitle";
import GoogleApiWrapper from '../Components/GoogleMap'
import './styles/contacts.css';
import {ScrollToTop} from "../Constants/ScrollToTop";
import {Helmet} from "react-helmet";
import logo from '../assets/Interiors.png'

export default class ContactsPage extends Component{
    state = {
        name: '',
        email: '',
        phone: '',
        message: '',
        checkbox: false,
        url: null
    };

    changeInputs = ({target}) => {
        this.setState({
            [target.name]: target.value
        })
    };

    componentDidMount() {
        ScrollToTop();
        this.setState({url: window.location.url})
    }

    contactsForm = () => {
        const { name, phone, message, checkbox, url } = this.state;
        const formSubmit = `mailto:request@vinteriors.store?subject=Vinteriors Request&body=${message} %0D%0A %0D%0A Name: ${name} %0D%0A Phone: ${phone}`
        return (
            <div className={'contacts-form'}>
                <Helmet>
                    <meta property="og:title" content={'Contacts — Vinteriors store.'}/>
                    <title>Contacts — Vinteriors store.</title>
                    <meta property="og:url" content={url}/>
                    <meta property="og:image" content={logo}/>
                    <meta property="description" content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                    <meta property="og:description" content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                    <meta property="twitter:title" content={'Contacts — Vinteriors store.'}/>
                    <meta property="twitter:description" content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                    <meta property="twitter:image" content={logo}/>
                    <meta name="twitter:card" content="summary_large_image"/>
                </Helmet>
                <div className={'w-50 order-1'}>
                    <div className={'form-block'}>
                        <h2>Email us</h2>
                        <p>Do you have any questions? Write to us and we will answer them within 24 hours</p>
                        <form>
                            <input placeholder={'Your name*'}
                                   name={'name'} onChange={this.changeInputs} value={name}
                                   required />
                            {/*<input placeholder={'Your E-mail*'}*/}
                            {/*       name={'email'} value={email} onChange={this.changeInputs}*/}
                            {/*       required />*/}
                            <input placeholder={'Your phone number*'}
                                   name={'phone'} onChange={this.changeInputs}
                                   value={phone} required />
                            <textarea placeholder={'Сообщение*'} name={'message'}
                                      rows={'6'} onChange={this.changeInputs}
                                      value={message}  required />
                            <a href={formSubmit}>Send form</a>
                            <label htmlFor={'policy-terms'} className={`checkbox ${checkbox ? 'checkbox-active' : '' }`}
                                   onClick={() => {
                                       this.setState(state => ({
                                           checkbox: !state.checkbox
                                       }))
                                   }}>
                                <input name={'policy-terms'} value={checkbox} type={'checkbox'} />
                                <span>I accept the terms of the user agreement</span>
                            </label>
                        </form>
                    </div>
                </div>
                <div className={'w-50 my-auto order-2'}>
                    <div className={'contacts-form-links'}>
                        <div className={'contacts-form-links-tablets'}>
                            {/*<div className={'w-50-tablets'}>*/}
                            {/*    <span>Phone number</span>*/}
                            {/*    <a href={'tel:+79160172103'}>+7 916 017 21 03</a>*/}
                            {/*</div>*/}
                            <div className={'w-50-tablets'}>
                                <span>E-mail</span>
                                <a href={'mailto:request@vinteriors.store'}>request@vinteriors.store</a>
                            </div>
                        </div>
                        {/*<div className={'contacts-form-links-tablets'}>*/}
                        {/*    <div className={'w-50-tablets'}>*/}
                        {/*        <span>office</span>*/}
                        {/*        <a href={'https://goo.gl/maps/xohoMH8qm2SMwd2x9'}*/}
                        {/*           target={'_blank'} rel="noopener noreferrer">*/}
                        {/*            Moscow, Michurinsky Prospect Street, Olympiyskaya Derevnya 1*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        )
    };

    render() {

        return (
            <div className={'wrapper_main_page'}>

                <PageTitle title={'Contacts'}/>

                <GoogleApiWrapper />

                {this.contactsForm()}
            </div>
        )
    }

}
