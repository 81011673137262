import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import './main_global.css'
import Routers from './Pages/Routers'
import Login from './AdminPages/Login'
import GoodsPage from './AdminPages/Goods';
import Variations from './AdminPages/Variations';
import BannersPage from './AdminPages/Banners';
import AuthorsPage from "./AdminPages/Authors";
import CategoriesPage from "./AdminPages/Categories";
import OrdersPage from "./AdminPages/Orders";
import SalesPage from "./AdminPages/Sales";
import StatsPage from "./AdminPages/Stats";
import UsersPage from "./AdminPages/Users";
import Reviews from "./AdminPages/Reviews";
import RequestsPage from "./AdminPages/Requests";
import SubscriptionsPage from "./AdminPages/Subscriptions";
import Brands from './AdminPages/Brands'
import Promocodes from './AdminPages/Promocodes'
import PrivateRoute from "./PrivateRouter";

const MainRouter = () => (
    <BrowserRouter>
        <Switch>
            {/*main*/}
            <Route exact path='/' component={Routers}/>
            <PrivateRoute exact path='/user_profile/:route'/>
            <Route exact path='/terms/:route' component={Routers}/>
            <Route exact path='/cart' component={Routers}/>
            {/*<Route exact path='/brands' component={Routers}/>*/}
            <Route exact path='/category_:id' component={Routers}/>
            <Route exact path='/catalogue/:route' component={Routers}/>
            <Route exact path='/goods/:id' component={Routers}/>
            <Route exact path='/about_company' component={Routers}/>
            <Route exact path='/category_:id/subcategory_:id' component={Routers}/>
            <Route exact path='/favorite_goods' component={Routers}/>
            <Route exact path='/contacts' component={Routers}/>
            <Route exact path='/catalogue/:route' component={Routers} />
            {/*<Route exact path='/brand/:route' component={Routers} />*/}
            {/*main*/}

            {/*admin*/}
            <Route exact path='/admin' component={Login}/>
            <Route exact path='/admin/goods/all' component={GoodsPage}/>
            <Route exact path='/admin/goods/:id/:id?' component={GoodsPage}/>
            <Route exact path='/admin/variations/:id' component={Variations}/>
            <Route exact path='/admin/brands' component={Brands}/>
            <Route exact path='/admin/banners' component={BannersPage}/>
            <Route exact path='/admin/authors' component={AuthorsPage}/>
            <Route exact path='/admin/categories/:id' component={CategoriesPage}/>
            <Route exact path='/admin/orders' component={OrdersPage}/>
            <Route exact path='/admin/sales' component={SalesPage}/>
            <Route exact path='/admin/stats' component={StatsPage}/>
            <Route exact path='/admin/reviews' component={Reviews}/>
            <Route exact path='/admin/users/:id?' component={UsersPage}/>
            <Route exact path='/admin/requests' component={RequestsPage}/>
            <Route exact path='/admin/subscriptions' component={SubscriptionsPage}/>
            <Route exact path='/admin/promocodes' component={Promocodes}/>
            {/*admin*/}
        </Switch>
    </BrowserRouter>
);

export default MainRouter
