import React, {useEffect, useRef, useState, useCallback} from 'react'
import './styles/header.css'
import shopping_cart from '../assets/shopping-cart.svg'
import loupe_icon from '../assets/loupe_icon.svg'
import vector_close from '../assets/vector_close.svg'
import heart_icon from '../assets/heart_icon.svg';
import arrow_back_gold from '../assets/arrow_back_gold.svg';
// import ru_flag from '../assets/flags/ru-flag.svg';
// import lang_arrow from '../assets/flags/flag-arrow.svg';
import { Link, useLocation} from "react-router-dom"
import {bodyOverflow} from "../Constants/bodyOverflow"
import {connect} from 'react-redux';
import {openSignModal, changeAuthActions, putName} from '../main-store/header/actions'
import AuthModal from './AuthModal'
import Input from '../Components/Input'
import API from "../API";
import icons_menu from '../assets/icons_menu.svg'
import v_interiors_gold_logo from '../assets/v_interiors_gold_logo.svg'
import helper from "../helper";
import useOutsideClick from "../Constants/useOutsideClick";

const Header = React.memo(props => {
    const [burger, changeBurger] = useState(false);
    const [searchInputActive, changeSearchInputActive] = useState(false);
    const [searchInputValue, changeSearchInputValue] = useState('');
    const [categories, getCategories] = useState(null);
    const [dataNavigation, getNavigation] = useState(null);
    // const [popup, setPopup] = useState(null)
    const [detectHoverClick, setDetectHoverClick] = useState(null)
    const [searchGoodsList, setSearchGoodsList] = useState(null)
    const [activeCategory, setActiveCategory] = useState(null)
    const [popupActiveCategory, setPopupActiveCategory] = useState(1)
    const [mobilePopupActiveCategory, setMobilePopupActiveCategory] = useState(null)
    const [windowWidth, setWindowWidth] = useState(null)
    let ref = useRef();
    const location = useLocation();

    useOutsideClick(ref, () => {
        if (ref.current.className === 'forRef') {
            setSearchGoodsList(null)
            if (mobilePopupActiveCategory) {
                setMobilePopupActiveCategory(null)
            }
        }
    })


    useEffect(() => {
        window.addEventListener('resize', () => {
            if (window.innerWidth <= 768) {
                changeSearchInputActive(false)
            }
        });
        setWindowWidth(window.innerWidth)

        window.addEventListener('resize', () => {
            setWindowWidth(window.innerWidth)
        });

        document.querySelector('.subcategory_popup')
            .addEventListener("mouseleave",
                () => {
                    setMobilePopupActiveCategory(null)
                });
    }, []);

    const searchInput = () => {
        return (
            <div className={searchInputActive ? 'wrapper_search_input_active' : 'wrapper_search_input'}>
                <Input type="text"
                       className={searchInputActive ? 'search_input' : 'search_input search_input_un_active'}
                       value={searchInputValue}
                       handleChange={(e) => {
                           let {value} = e.target;
                           changeSearchInputValue(value)
                           API.get(`/search?name=${value}`)
                               .then(res => setSearchGoodsList(res.data.product))
                       }}
                       placeholder={'Search among 200 000 products'}
                       maxLength={30}/>
                {searchGoodsList?.length ?
                    <div className={'wrapper_search_list overflow_scroll'}>
                        {searchGoodsList.map(item => {
                            return (
                                <Link to={`/goods/${item.product_id}`}
                                      onClick={() => {
                                          console.log('123')
                                          changeBurger(false)
                                          setDetectHoverClick(true)
                                          setTimeout(() => {
                                              setDetectHoverClick(false)
                                          }, 100)
                                          setSearchGoodsList(null)
                                          bodyOverflow(false)
                                      }}
                                      className={'wrapper_every_items'}
                                      key={item.product_id}>
                                    <div className={'wrapper_item_image'}>
                                        <img src={process.env.REACT_APP_IMAGE_API_URL + '/' + item.photo} alt={item.name}/>
                                    </div>
                                    <div className={'wrapper_info align_block'}>
                                        <div className={'wrapper_item_desc open_sans_400'}>
                                            {item.brand.length > 20 ? item.brand.substring(0, 20) + '...' : item.brand}
                                        </div>
                                        <div className={'wrapper_item_title playfair_display_400'}>
                                            {item.name.length > 20 ? item.name.substring(0, 14) + '...' : item.name}
                                        </div>
                                    </div>
                                    <div className={'wrapper_item_price align_block'}>
                            <span className={'open_sans_400'}>
                                {item.price ? '€' + item.price : null}
                            </span>
                                    </div>
                                </Link>
                            )
                        })}
                    </div>
                    : null}
            </div>
        )
    };

    const navigation = useCallback(() => {
        let everyLinksClass = 'playfair_display_400 every_navigation_links';
        return (
            <div style={{display: 'inline-flex'}}>
                <img src={icons_menu} alt={"icons_menu"}
                     style={{width: '20px', height: '20px', padding: '15px 0 0 10px', display: 'none'}}/>
                <div className={'navigation'} style={searchInputActive ? {display: 'none'} : null}>
                    {categories?.map(categories => {
                        return (
                            <a href={`/category_${categories.category_id}`}
                               key={categories.category_id}
                               onClick={() => {
                                   setDetectHoverClick(true)
                                   setTimeout(() => {
                                       setDetectHoverClick(false)
                                   }, 100)
                                   setSearchGoodsList(null)
                                   bodyOverflow(false)
                                   changeBurger(false)
                               }}
                               className={categories.category_id === activeCategory ?
                                   `active_nav_link ${everyLinksClass}` :
                                   everyLinksClass}>
                                {categories.name}
                            </a>
                        )
                    })}
                    {/*<a href={`/brands`}*/}
                    {/*   key={'brands'}*/}
                    {/*   onClick={() => {*/}
                    {/*       setDetectHoverClick(true)*/}
                    {/*       setTimeout(() => {*/}
                    {/*           setDetectHoverClick(false)*/}
                    {/*       }, 100)*/}
                    {/*       setSearchGoodsList(null)*/}
                    {/*       bodyOverflow(false)*/}
                    {/*       changeBurger(false)*/}
                    {/*   }}*/}
                    {/*   className={activeCategory === 'brands' ?*/}
                    {/*       `active_nav_link ${everyLinksClass}` :*/}
                    {/*       everyLinksClass}>*/}
                    {/*    {'BRANDS'}*/}
                    {/*</a>*/}
                </div>
            </div>
        )
    }, [activeCategory, categories, searchInputActive]);

    const search_and_cart_wrapper = () => {
        return (
            <div className={searchInputActive ?
                'search_and_cart_wrapper search_and_cart_wrapper_active' :
                'search_and_cart_wrapper'}
                 style={searchInputActive ? {gridColumn: '1/-1'} : null}>
                <div className={searchInputActive ? 'input_icon_search_active input_icon_search' : 'input_icon_search'}>
                    {searchInput()}
                    <img src={loupe_icon} alt="loupe_icon"
                         onClick={() => {
                             setDetectHoverClick(true)
                             setTimeout(() => {
                                 setDetectHoverClick(false)
                             }, 100)
                             setSearchGoodsList(null)
                             changeSearchInputActive(true)
                         }}/>
                    <img src={vector_close} alt="close"
                         onClick={() => {
                             setDetectHoverClick(true)
                             setSearchGoodsList(null)
                             changeSearchInputActive(false)
                         }}
                         className={searchInputActive ? 'vector_close_search_input_active' : 'vector_close_search_input'}/>
                </div>
                <Link to={'/favorite_goods'}
                      onClick={() => {
                          changeBurger(false);
                          bodyOverflow(false);
                          // setPopup(false)
                          setDetectHoverClick(true)
                      }}
                      className={'wrapper_cart'}>
                    <img src={heart_icon} alt={"heart_icon"} style={{width: "22px"}}/>
                </Link>
                <Link to={'/cart'} className={'wrapper_cart'}
                      onClick={() => {
                          setDetectHoverClick(true)
                          setSearchGoodsList(null)
                          changeBurger(false);
                          bodyOverflow(false)
                          // setPopup(false)
                      }}>
                    <img src={shopping_cart} alt="shopping-cart"/>
                    {props.cart.length ?
                        <div className={'cart_length'}>
                            <span>{props.cart.length}</span>
                        </div> : null}
                </Link>
            </div>
        )
    };

    const handleAuth = sign => {
        bodyOverflow(true)
        if (sign === 'login') {
            props.changeAuthActions('sign_in')
        } else {
            props.changeAuthActions('sign_up')
        }
        if (burger) {
            changeBurger(false);
        }
        props.openSignModal(true);
    };

    const signModal = () => {
        let token = localStorage.getItem(helper.JWT_TOKEN);
        return (
            <div className={'header-lang-profile'}>
                <span onClick={() => window.open('https://vinteriors.store/contacts')} className={'request-design'}>Order interior design</span>
                {/*<div onClick={() => window.open('https://vesperinteriors.ru/', '_self')} className={'lang-change'}>*/}
                {/*    <img src={ru_flag} alt=""/>*/}
                {/*    <span>Ru</span>*/}
                {/*    <img src={lang_arrow} alt=""/>*/}
                {/*</div>*/}
                <div className={'wrapper_sign_popup open_sans_500'}
                     style={token ? {border: 'none', display: 'block'} : null}>

                    {token ?
                        <Link to={'/user_profile/user_data'}
                              onClick={() => {
                                  setDetectHoverClick(true)
                                  setTimeout(() => {
                                      setDetectHoverClick(false)
                                  }, 100)
                                  setSearchGoodsList(null)
                                  bodyOverflow(false)
                              }}
                              className={'header_user_name roboto_500'}>
                            {props.user_name}
                        </Link>
                        : <>
                        <span onClick={() => {
                            setDetectHoverClick(true)
                            setTimeout(() => {
                                setDetectHoverClick(false)
                            }, 100)
                            setSearchGoodsList(null)
                            handleAuth('login')
                        }}>Login</span>
                            <span style={{padding: '0 4px'}}>/</span>
                            <span onClick={() => {
                                setDetectHoverClick(true)
                                setTimeout(() => {
                                    setDetectHoverClick(false)
                                }, 100)
                                setSearchGoodsList(null)
                                handleAuth()
                            }}>Register</span>
                        </>
                    }
                </div>
            </div>
        )
    };

    const switchToMobiliConnection = (link, value) => {
        return (
            <a href={link}
               onClick={() => {
                   setDetectHoverClick(true)
                   setTimeout(() => {
                       setDetectHoverClick(false)
                   }, 100)
                   setSearchGoodsList(null)
                   bodyOverflow(false)
               }}
               className={'roboto_400'}>
                {value}
            </a>
        )
    };

    useEffect(() => {
        API.get('/categories_and_brands')
            .then(res => {
                getNavigation(res.data)
            });
        API.get('/categories')
            .then(res => {
                if (res.data) {
                    getCategories(res.data)
                }
            })
    }, []);

    useEffect(() => {
        let url = location.pathname;
        if (url.includes('category')) {
            let activeCategory = url.split('/')[1].split('_').pop();
            setActiveCategory(Number(activeCategory))
        } else if (url.includes('brands')) {
            setActiveCategory('brands')
        }
    }, [location.pathname])

    let subcategoryBrands = () => {
        return (
            <div className={'every_subcategory_brands'}>
                <div className={'brand_title'}>
                    {'Brands'}
                </div>
                {subcategoryBrandsArray()}
            </div>
        )
    }

    let subcategoryBrandsArray = () => {
        return (
            null
            // dataNavigation?.brands.map(brand => {
            //     return (
            //         <NavLink to={`/catalogue/brand=${brand.id}`}
            //                  key={brand.id}
            //                  onClick={() => {
            //                      // setPopup(false)
            //                      setDetectHoverClick(true)
            //                      setTimeout(() => {
            //                          setDetectHoverClick(false)
            //                      }, 100)
            //                  }}
            //                  className={'photo_brands'}>
            //             <img src={`${process.env.REACT_APP_IMAGE_API_URL}/${brand.photo}`}
            //                  alt={"brand"}
            //             />
            //         </NavLink>
            //     )
            // })
        )
    }

    return (
        <>
            <header ref={ref} className={!detectHoverClick ? 'forRef' : 'forRef detectHoverClick'}>
                <Link to={'/'} className={'wrapper_logo'}
                      onClick={() => {
                          if (burger) {
                              changeBurger(false)
                              setDetectHoverClick(true)
                          } else {
                              setSearchGoodsList(null)
                              // setPopup(false)
                              changeBurger(false)
                              // setDetectHoverClick(true)
                              // setTimeout(() => {
                              setDetectHoverClick(false)
                              // }, 100)
                              bodyOverflow(false)
                          }
                      }}
                >
                    <div style={{justifySelf: 'center', alignSelf: 'end'}}>
                        <span className={'playfair_display_400 logo'}>
                            {/*<img src={v_interiors_gold_o} alt={"v_interiors_gold_logo"} style={{width: '116px', height: '66px'}}/>*/}
                            <img src={v_interiors_gold_logo} alt={"v_interiors_gold_logo"}
                                 style={{width: '116px', height: '66px'}}/>
                        </span>
                        {/*<img src={dropdown} alt={"dropdown"} style={{width: '20px', height: '13px', placeSelf: 'end'}}/>*/}
                    </div>
                </Link>
                <div className={'wrapper_header_navigation'}>
                    <div className={'wrapper_info_in_header'}>
                        {switchToMobiliConnection('mailto:request@vinteriors.store', 'request@vinteriors.store')}
                        {/*{switchToMobiliConnection('tel:+7 958 111-48-24', '+7 958 111-48-24')}*/}
                        {signModal()}
                    </div>
                    <div className={'header_navigation'}>
                        {navigation()}
                        <div className={'wrapper_burger_menu'}
                             onClick={() => {
                                 setDetectHoverClick(true)
                                 setTimeout(() => {
                                     setDetectHoverClick(false)
                                 }, 100)
                                 setSearchGoodsList(null)
                                 bodyOverflow(!burger);
                                 changeBurger(!burger)
                             }}>
                            <div className={burger ? 'burger_menu_active burger_menu' : 'burger_menu'}/>
                        </div>
                        {search_and_cart_wrapper()}
                    </div>
                </div>
                <div className={'subcategory_popup'}>
                    <div className={'every_subcategory_menu'}>
                        {dataNavigation && (
                            <div className={'every_subcategory_menu__category_name'}>
                                {!mobilePopupActiveCategory ?
                                    dataNavigation?.categories.map(category => {
                                        return (
                                            <span key={category.id}
                                                  onClick={() => {
                                                      if (windowWidth <= 572) {
                                                          setMobilePopupActiveCategory(category.id)
                                                      } else {
                                                          setPopupActiveCategory(category.id)
                                                      }
                                                  }}
                                                  className={category.id === popupActiveCategory ? 'active' : null}>
                                                {category.name}
                                            </span>
                                        )
                                    }) : mobilePopupActiveCategory === 'brands' ?
                                        null
                                        // <div className={'every_subcategory_brands'}>
                                        //     {dataNavigation?.brands.map(brand => {
                                        //         return (
                                        //             <NavLink to={`/catalogue/brand=${brand.id}`}
                                        //                      key={brand.id}
                                        //                      onClick={() => {
                                        //                          // setPopup(false)
                                        //                          setDetectHoverClick(true)
                                        //                          setTimeout(() => {
                                        //                              setDetectHoverClick(false)
                                        //                          }, 100)
                                        //                      }}
                                        //                      className={'photo_brands'}>
                                        //                 <img src={`${process.env.REACT_APP_IMAGE_API_URL}/${brand.photo}`}
                                        //                      alt={"brand"}
                                        //                 />
                                        //             </NavLink>
                                        //         )
                                        //     })}
                                        // </div>
                                        : null
                                        // dataNavigation?.categories.map(category => {
                                        //     return category.id === popupActiveCategory ? (
                                        //         category.subcategories.map(subcategory => {
                                        //             return subcategory.name && (
                                        //                 <a href={`/category_${category.id}/subcategory_${subcategory.id}`}
                                        //                    key={subcategory.id}
                                        //                    onClick={() => {
                                        //                        // bodyOverflow(false)
                                        //                        // setSearchGoodsList(null)
                                        //                        // changeBurger(false)
                                        //                        // setPopup(false)
                                        //                        // setDetectHoverClick(true)
                                        //                        // setTimeout(() => {
                                        //                        //     setDetectHoverClick(false)
                                        //                        // }, 100)
                                        //                    }}>
                                        //                     {subcategory.name}
                                        //                 </a>
                                        //             )
                                        //         })
                                        //     ) : null
                                        // })
                                }
                                {windowWidth <= 800 && !mobilePopupActiveCategory ?
                                    <span onClick={() => {
                                        // bodyOverflow(false)
                                        // setSearchGoodsList(null)
                                        // changeBurger(false)
                                        // setPopup(false)
                                        // setDetectHoverClick(true)
                                        // setTimeout(() => {
                                        //     setDetectHoverClick(false)
                                        // }, 100)
                                        if (windowWidth <= 572) {
                                            setMobilePopupActiveCategory('brands')
                                        } else {
                                            setPopupActiveCategory('brands')
                                        }
                                        // setPopupActiveCategory(category.id)
                                    }}
                                          className={popupActiveCategory === 'brands' ? 'active' : null}>
                                    {'Brands'}
                                </span>
                                    : null}
                            </div>
                        )}

                        <div className={'every_subcategory_menu__subcategory_name'}>
                            {/*{window.innerWidth <= 572}*/}
                            {dataNavigation?.categories.map(category => {
                                return category.id === popupActiveCategory ? (
                                    category.subcategories.map(subcategory => {
                                        return subcategory.name && (
                                            <a href={`/category_${category.id}/subcategory_${subcategory.id}`}
                                               key={subcategory.id}
                                               onClick={() => {
                                                   // bodyOverflow(false)
                                                   // setSearchGoodsList(null)
                                                   // changeBurger(false)
                                                   // setPopup(false)
                                                   // setDetectHoverClick(true)
                                                   // setTimeout(() => {
                                                   //     setDetectHoverClick(false)
                                                   // }, 100)
                                               }}>
                                                {subcategory.name}
                                            </a>
                                        )
                                    })
                                ) : null
                            })}
                            {popupActiveCategory === 'brands' ?
                                <div className={'subcategory_brands_active_popup'}>
                                    {subcategoryBrandsArray()}
                                </div>
                                : windowWidth >= 715 ?
                                    subcategoryBrands()
                                    : null}
                        </div>
                        <div className={'header-other-services'}>
                            <span>Other services</span>
                        </div>
                        <div className={'other-services-button-wrapper'}>
                            <button onClick={() => window.open('https://vinteriors.store/contacts')}>ORDER INTERIOR DESIGN</button>
                        </div>
                    </div>
                    <img src={mobilePopupActiveCategory ? arrow_back_gold : vector_close} alt="vector_close"
                         onClick={() => {
                             if (mobilePopupActiveCategory) {
                                 // setPopupActiveCategory(null)
                                 setMobilePopupActiveCategory(null)
                             } else {
                                 setDetectHoverClick(true)
                                 setSearchGoodsList(null)
                                 // setPopup(false)
                             }
                         }}
                         className={'image_small header_mobile_close'}/>
                    {subcategoryBrands()}
                    <img src={mobilePopupActiveCategory ? arrow_back_gold : vector_close} alt="close"
                         onClick={() => {
                             if (mobilePopupActiveCategory) {
                                 setMobilePopupActiveCategory(null)
                                 // setPopupActiveCategory(null)
                             } else {
                                 setDetectHoverClick(true)
                                 setTimeout(() => {
                                     setDetectHoverClick(false)
                                 }, 100)
                                 setSearchGoodsList(null)
                                 // setPopup(false)
                             }
                         }} className={'image'}/>
                </div>
                {/*burger_menu_navigation*/}
                <div
                    className={burger ? 'wrapper_burger_navigation_active' : 'wrapper_burger_navigation_active wrapper_burger_navigation_un_active'}>
                    <div className={'burger_navigation_scroll'}>
                        <div className={'header_navigation'}>
                            {signModal()}
                            {search_and_cart_wrapper()}
                            <div className={'wrapper_input_search'}>
                                <img src={loupe_icon} alt="loupe_icon"/>
                                <img src={heart_icon} alt={"heart_icon"} className={'favorite'}/>
                                {searchInput()}
                            </div>
                            {navigation()}
                            <div className={'wrapper_mobili_connection'}>
                                {switchToMobiliConnection('mailto:request@vinteriors.store', 'request@vinteriors.store')}
                                {/*{switchToMobiliConnection('tel:+7 958 111-48-24', '+7 958 111-48-24')}*/}
                            </div>
                            <div className={'header-mobile-services'}>
                                <span>Other services</span>
                                <button onClick={() => window.open('https://vinteriors.store/contacts')}>Order interior design</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*burger_menu_navigation*/}


            </header>
            {/*auth_modal*/}
            {props.sign_modal ?
                <AuthModal sign_modal={props.sign_modal}
                           sign_relocate={props.sign_relocate}
                           vector_close={vector_close}
                           openSignModal={props.openSignModal}
                           putName={props.putName}
                           changeAuthActions={props.changeAuthActions}
                           auth_action={props.auth_action}/>
                : null}
            {/*auth_modal*/}
        </>
    )
})

const mapPropsToState = state => {
    return {
        sign_modal: state.header.sign_modal,
        auth_action: state.header.auth_action,
        user_name: state.header.user_name,
        cart: state.cart_favorite.cart,
        sign_relocate: state.header.sign_relocate
    }
};

const putStateToProps = {
    openSignModal,
    changeAuthActions,
    putName
};

export default connect(mapPropsToState, putStateToProps)(Header)
